import { Alert, Button, Card, Col, Form, Row, Spin, Tag, Tooltip, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import useAuth from 'redux/auth/auth.hook';
import CameraRemoteAdvanced from './advanced';
import CameraRemoteBasic from './basic';
import SDCardUsageMC2 from '../cameraSimbols/SDCardUsageMC2';
import BatteryComponentMC2 from '../cameraSimbols/BatteryComponentMC2';
import SignalComponentMC2 from '../cameraSimbols/SignalComponentMC2';
import classes from './style.module.scss';

const format = 'HH:mm';

const CameraRemoteMiniDC2B = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [settingBasicInit, setSettingBasicInit] = useState<any>(null);
  const [settingAdvancedInit, setSettingAdvancedInit] = useState<any>(null);
  const [settingBasic, setSettingBasic] = useState<any>(null);
  const [settingAdvanced, setSettingAdvanced] = useState<any>(null);
  const [connectStatus, setConnectStatus] = useState<String>('HTTPS');
  const [deviceSetting, setDeviceSetting] = useState<any>(null);

  useEffect(() => {
    http_load_device_setting(props.camera.IMEI);
  }, [props]);

  useEffect(() => {
    console.log('deviceSetting', deviceSetting);

    if (deviceSetting) {
      const newSettingBasic = {
        mode: deviceSetting.mode,
        multi_shot: deviceSetting.multi_shot,
        video_length: deviceSetting.video_length,
        video_size: deviceSetting.video_size,
        name: deviceSetting.name,
        motion_sensitivity: deviceSetting.motion_sensitivity,
        transvideo: deviceSetting.transvideo,
        remote_ctrl: deviceSetting.remote_ctrl,
        frequency: deviceSetting.frequency,
        delay_time: deviceSetting.delay_time,
        pic_size: deviceSetting.pic_size,
      };
      setSettingBasic(newSettingBasic);
      setSettingBasicInit(newSettingBasic);

      const newSettingAdvanced = {
        pir_interval: deviceSetting.pir_interval,
        work_timer_1: deviceSetting.work_timer_1,
        work_timer_2: deviceSetting.work_timer_2,
        burst_interval: deviceSetting.burst_interval,
        flash_distance: deviceSetting.flash_distance,
        gps_on: deviceSetting.gps_on,
        pir_switch: deviceSetting.pir_switch,
        // verizon_priority: deviceSetting.verizon_priority,
      };
      setSettingAdvanced(newSettingAdvanced);
      setSettingAdvancedInit(newSettingAdvanced);
    }
  }, [deviceSetting]);

  const getLabel = (value, options) => {
    for (let i = 0; i < options.length; i++) {
      if (parseInt(value, 10) == options[i].value) {
        return options[i].label;
      }
    }
    return '';
  };

  const http_load_device_setting = (IMEI) => {
    setLoading(true);
    Api.DC2B_SETTING_GET(IMEI).then(((data: any) => {
      setLoading(false);
      const res = JSON.parse(data.text);
      console.log(res);
      if (res.result == 'OK') {
        setDeviceSetting(res.setting);
        setConnectStatus(res.connect);
      }
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const onChangeAdvanced = (values) => {
    setSettingAdvanced(values);
  };

  const onChangeBasic = (values) => {
    setSettingBasic(values);
  };

  const onSend = () => {
    http_update_device_setting(settingBasic, settingAdvanced);
  };

  const http_update_device_setting = (basic, advanced) => {
    const different_settings = { ...basic, ...advanced };
    console.log('http_update_device_setting', different_settings);
    const params = {
      IMEI: props.camera.IMEI,
      setting: different_settings,
    };

    setLoading(true);
    Api.DC2B_SETTING_UPDATE(params).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: 'Your new settings have been sent to your camera and will take effect the next time your camera connects to the server.',
      });
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  function getIndicator(status) {
    if (status == 'Online') {
      return <Tag color="#dc6300">Always Available</Tag>;
    }
    if (status == 'Offline') {
      return <Tag color="gray">Always Available</Tag>;
    }
    return <Tag color="#2db7f5">4 Times Daily</Tag>;
  }

  return (
    <>
      <Spin spinning={loading} size="large">
        <Card className={classes.card}>
          <div className={classes.divBottomHeader}>
            {deviceSetting && (
              <div className={classes.divFirmware}>
                <Row className={classes.labelFirmware}>
                  <Col md={12} xs={24}>
                    <h1 className={classes.labelTitle}>
                      {props.camera.name}<span> </span>
                      <Tooltip title="Reload the settings">
                        <Button shape="circle" icon={<ReloadOutlined />} onClick={() => http_load_device_setting(props.camera.IMEI)} />
                      </Tooltip>
                    </h1>
                    <p className={classes.pDescription}>Last Synced: {props.device.last_text_uploaded_at > 1000 ? moment(props.device.last_text_uploaded_at * 1000).format('hh:mm A - MMM D, Y') : ''}</p>
                    <p className={classes.pDescription}>Firmware Version: {props.device.firmware_version}</p>
                    <p className={classes.pDescription}>{getIndicator(connectStatus)}</p>
                  </Col>
                  <Col md={12} xs={24}>
                    <div className={classes.divBottomContent}>
                      <div className={classes.divCameraType}>
                        {/* <CameraVersion detail={props.device} size={md ? 100 : 60} /> */}
                      </div>
                      <div className={classes.divImages}>
                        <div className={classes.divMemory}>
                          <img className={classes.image} alt="test" src="/icons_camera/camera_memory.png" />
                          <p>{props.device.internal_memory}</p>
                        </div>
                        <SDCardUsageMC2 usage={props.device.sd_card} max={props.device.sd_card_max} percent={false} />
                        <BatteryComponentMC2 battery1={props.device.battery1} battery2={props.device.battery2} />
                        <div className={classes.iconSignal}>
                          <SignalComponentMC2 csq_percent={props.device.csq_percent} fontSize="large" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Card>
        <CameraRemoteBasic IMEI={props.camera.IMEI} setting={settingBasicInit} onChange={onChangeBasic} onSend={onSend} />
        <CameraRemoteAdvanced IMEI={props.camera.IMEI} setting={settingAdvancedInit} onChange={onChangeAdvanced} onSend={onSend} isLastFirmware={props.device.is_last_firmware} />
      </Spin>
    </>
  );
};

export default CameraRemoteMiniDC2B;
